footer {
  text-align: right;
  position:'';  
  bottom: 0rem;
  height: 2.5rem;
  width: 100%;
  margin-top: 10%;
  margin-bottom: 10px;
}

p {
  color: #000000;
  font-family:'Roboto', sans-serif;
  font-weight: 700;
}

.button {
  height: 40px;
  width: 200px;
  background-color: white;
  color: black;
  border: 3px solid #000000;
  border-radius: 25px;
  margin: 10px;
  font-size: large;
  font-family:'Roboto', sans-serif; 
  font-weight:bold;
   
}

.button:hover {
  filter: drop-shadow(6px 6px 6px #ffa6a6);
}


h1 {
  color: #000000;
  text-align: left;
  display: flex;
  align-self: center;
  font-family: 'roboto';
  font-weight: bold;
}

h3 {
  text-decoration: underline;
}

.card {
  border: 2px, solid, #ff0000 !important;
}

.card-body {
  background-color: #ffffff;
  border: #000000 !important;
}

.art-card-container {
  display: flex;
  position: relative;
  align-items: baseline;
  justify-content: center;
  text-align: left;
}

.container {
  
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.icon {
  width: 50px;
  margin: 5px;

 
} 



.contact {
  height: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  

}

#aboutmedescr {
  margin-top: 20px;
}

.btn-primary {
  background-color: #ffffff !important;
  border-color: #000000 !important;
  color:#000000 !important;
}

.nav-link {
color: #000000 !important;
}



h6 {
  color: rgb(151, 151, 151);
  
}

.art-card {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;

}
@media (max-width: 991px){
  .contact{
      flex-direction: column;
  }
  .container{
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
  }
  img{
    align-content: center;
    max-width: 100% !important;
  }

  
}
